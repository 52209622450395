import React from 'react'
import styled from 'styled-components'

import ButtonWithIcon from 'components/shared/button-with-icon'
import LazyImage from 'components/shared/lazy-image'
import { Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import phoneIcon from 'assets/icons/phone.svg'
import emailIcon from 'assets/icons/email.svg'

import type { SalesRep } from 'types/homepage'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  ${media.md.min} {
    grid-template-columns: repeat(2, max-content);
  }

  ${media.lg.min} {
    grid-template-columns: repeat(3, 1fr);
    gap: 33px;
  }
`

const SinglePersonWrapper = styled.div`
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  margin-bottom: 20px;
`

const PersonInfoWrapper = styled.div`
  height: 100%;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;

  a {
    width: 250px;
    padding: 15px;
    text-transform: lowercase;
    &:last-of-type {
      margin-top: 5px;
    }
  }
`

const MobileButtonsWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`

const MobileButton = styled.button`
  background: ${({ theme }) => theme.colors.black};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;

  &:not(&:first-child) {
    margin-left: 5px;
  }
`

type Props = {
  people: SalesRep[]
}

const People: React.FC<Props> = ({ people }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      {people?.map(({ image, name, position, region, phone, email }) => (
        <SinglePersonWrapper key={name}>
          <ImageWrapper>
            <LazyImage src={image.src} alt={image.alt} />
          </ImageWrapper>
          <PersonInfoWrapper>
            <Text
              dangerouslySetInnerHTML={{ __html: name }}
              size={22}
              margin="0px"
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: `<b>${position}</b>`,
              }}
              margin="0"
            />
            {region && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: `${region}`,
                }}
                margin="0"
              />
            )}
          </PersonInfoWrapper>
          {lg ? (
            <ButtonsWrapper>
              <ButtonWithIcon
                as="a"
                href={`tel:${phone}`}
                icon="phone"
                aria-label="zadzwoń"
              >
                {phone}
              </ButtonWithIcon>
              <ButtonWithIcon
                as="a"
                href={`mailto:${email}`}
                icon="email"
                aria-label="wyślij email"
              >
                {email}
              </ButtonWithIcon>
            </ButtonsWrapper>
          ) : (
            <MobileButtonsWrapper>
              <MobileButton as="a" href={`tel:${phone}`} aria-label="zadzwoń">
                <Icon src={phoneIcon} />
              </MobileButton>
              <MobileButton
                as="a"
                href={`mailto:${email}`}
                aria-label="wyślij email"
              >
                <Icon src={emailIcon} />
              </MobileButton>
            </MobileButtonsWrapper>
          )}
        </SinglePersonWrapper>
      ))}
    </Wrapper>
  )
}

export default People
