import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'

import Intro from 'components/pages/contact/representatives/intro'
import People from 'components/pages/contact/representatives/people'

import media from 'styles/media'

import type { SalesRep } from 'types/homepage'

const Wrapper = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
  }
`

type Props = {
  heading: string
  text: string
  people: SalesRep[]
}

const Representatives: React.FC<Props> = ({ heading, text, people }) => {
  return (
    <Container>
      <Wrapper>
        <Intro heading={heading} text={text} />
        <People people={people} />
      </Wrapper>
    </Container>
  )
}

export default Representatives
