import React from 'react'
import styled from 'styled-components'

import { Heading, Text } from 'components/shared/typography'

import media from 'styles/media'

const Wrapper = styled.div`
  margin-bottom: 40px;

  ${media.lg.min} {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const InnerWrapper = styled.div``

type Props = {
  heading: string
  text: string
}

const Intro: React.FC<Props> = ({ heading, text }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Heading
          as="h4"
          transform="uppercase"
          line="100%"
          size={56}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Text dangerouslySetInnerHTML={{ __html: text }} />
      </InnerWrapper>
    </Wrapper>
  )
}

export default Intro
