import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import ContactForm from 'components/shared/contact-form'

import Info from 'components/pages/contact/contact/info'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  padding: 40px;

  ${media.lg.min} {
    padding: 80px;
  }
`

const StyledHeading = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Kanit';
  font-weight: normal;
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;

  ${media.lg.min} {
    font-size: 24px;
    margin-bottom: 50px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;

    > * {
      width: 50%;
    }
  }
`

const FormWrapper = styled.div`
  /* temporary */
  min-height: 25vh;
`

type Props = {
  heading: string
  contactData: {
    address: string
    companyData: string
    phone: string
    email: string
  }
}

const Contact: React.FC<Props> = ({ heading, contactData }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledContainer>
      <Wrapper>
        {lg && <StyledHeading dangerouslySetInnerHTML={{ __html: heading }} />}
        <InnerWrapper>
          <Info contactData={contactData} />
          <FormWrapper>
            {!lg && (
              <StyledHeading dangerouslySetInnerHTML={{ __html: heading }} />
            )}
            <ContactForm />
          </FormWrapper>
        </InnerWrapper>
      </Wrapper>
    </StyledContainer>
  )
}

export default Contact
