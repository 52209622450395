import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import axios from 'axios'

import { Text } from 'components/shared/typography'
import ButtonWithIcon from 'components/shared/button-with-icon'

import Input from 'components/shared/modal-form/input'
import Checkbox from 'components/shared/modal-form/checkbox'

import { CONTACT_FORM_SCHEMA, CONTACT_FORM_INIT_VALUES } from 'constants/form'

import useLocation from 'hooks/useLocation'

import media from 'styles/media'

import type { ContactFormValues } from 'types/form'

const StyledForm = styled(Form)`
  margin-bottom: 0;

  > * {
    margin-bottom: 10px;
  }

  button:last-child {
    margin-top: 20px;
    width: 100%;

    font-weight: 600;
    font-size: 18px;
    padding: 18px 20px;

    ${media.lg.min} {
      width: 230px;
      font-size: 16px;
      padding: 10px 18px;
    }
  }
`

const StyledInputsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${media.lg.min} {
    flex-direction: row;
  }
`

const StyledTermsWrapper = styled.div`
  margin: 5px 0;
`

const StyledFormMessage = styled.p<{ error?: boolean }>`
  color: ${({ theme }) => theme.colors.white};

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.danger};
    `};
`

const ContactForm = () => {
  const [formInfo, setFormInfo] = useState({
    text: '',
    error: false,
  })
  const ref = useRef(null)

  const path = useLocation()

  const currentUrl = `https://cfmoto.pl${path}`

  return (
    <Formik
      innerRef={ref}
      initialValues={CONTACT_FORM_INIT_VALUES}
      validationSchema={CONTACT_FORM_SCHEMA}
      onSubmit={(
        values,
        { setSubmitting, resetForm }: FormikHelpers<ContactFormValues>
      ) => {
        if (formInfo.text && !formInfo.error) return
        ;(async () => {
          try {
            setSubmitting(true)

            const query = `
              mutation {
                sendContactForm(
                  input: { name: "${values.name} ${
              values.surname
            }", message: "${values.message}", email: "${
              values.email
            }", url: "${currentUrl}", number: "${
              values.phone || ''
            }", agree1: ${values.agree1}}
                ) {
                  code
                  status
                }
              }
              `

            await axios.post(`https://cms.cfmotoatv.pl/graphql`, {
              query,
            })

            setSubmitting(false)
            setFormInfo({
              text: 'Dziękujemy za wysłanie formularza!',
              error: false,
            })

            setTimeout(() => {
              setFormInfo({ text: '', error: false })
              resetForm()
            }, 5000)
          } catch (err) {
            setSubmitting(false)
            setFormInfo({ text: 'Coś poszło nie tak!', error: true })
            setTimeout(() => {
              setFormInfo({ text: '', error: false })
            }, 5000)
          }
        })()
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <StyledForm>
          <StyledInputsRow>
            <Field name="name" label="Imię" component={Input} required />
            <Field name="surname" label="Nazwisko" component={Input} required />
          </StyledInputsRow>

          <StyledInputsRow>
            <Field
              name="email"
              label="Adres e-mail"
              component={Input}
              required
            />
            <Field name="phone" label="Numer telefonu" component={Input} />
          </StyledInputsRow>

          <Field name="message" label="Wiadomość" component={Input} textarea />

          <StyledTermsWrapper>
            <Field
              type="checkbox"
              name="agree1"
              label="Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu kontaktowym w celu udzielenia odpowiedzi na zadane przeze mnie pytanie zgodnie z przepisami Ogólnego Rozporządzenia o Ochronie Danych Osobowych (RODO) i Polityki prywatności."
              component={Checkbox}
              white
            />

            {Boolean(touched.agree1 && errors.agree1) && (
              <Text
                dangerouslySetInnerHTML={{ __html: errors.agree1! }}
                themecolor="danger"
              />
            )}
          </StyledTermsWrapper>

          {formInfo.text && (
            <StyledFormMessage error={formInfo.error}>
              {formInfo.text}
            </StyledFormMessage>
          )}

          <ButtonWithIcon
            disabled={!!(formInfo.text && !formInfo.error)}
            loading={isSubmitting}
            white
          >
            Wyślij wiadomość
          </ButtonWithIcon>
        </StyledForm>
      )}
    </Formik>
  )
}

export default ContactForm
