import React from 'react'
import styled from 'styled-components'

import media from 'styles/media'

const StyledHeading = styled.h1`
  padding: 120px 0 40px;
  font-size: 28px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;

  ${media.lg.min} {
    padding: 185px 0 80px;
    font-size: 55px;
  }
`

const Title: React.FC<{ title: string }> = ({ title }) => {
  return <StyledHeading dangerouslySetInnerHTML={{ __html: title }} />
}

export default Title
