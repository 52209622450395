import { ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import ButtonWithIcon from 'components/shared/button-with-icon'

import media from 'styles/media'

const Wrapper = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
  }
`

const ContentWrapper = styled.div`
  padding: 0 22px 44px;

  ${media.lg.min} {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 48px;
    padding-right: 96px;
    width: 35%;

    a {
      max-width: 300px;
    }
  }

  ${media.xxxl.min} {
    padding-left: calc(((100vw - 1600px) + 48px) / 2);
  }
`

const ImageWrapper = styled.div`
  height: 40vh;

  ${media.lg.min} {
    height: auto;
    width: 65%;
  }
`

type Props = {
  heading: string
  text: string
  buttonText: string
  buttonLink: string
  image: {
    src: ImageDataLike
    alt: string
  }
}

const Questions: React.FC<Props> = ({
  heading,
  text,
  buttonText,
  buttonLink,
  image,
}) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Heading
          as="h2"
          transform="uppercase"
          size={56}
          line="100%"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Text dangerouslySetInnerHTML={{ __html: text }} margin="24px" />
        <ButtonWithIcon as={Link} to={buttonLink}>
          {buttonText}
        </ButtonWithIcon>
      </ContentWrapper>
      <ImageWrapper>
        <LazyImage src={image?.src} alt={image?.alt} fill />
      </ImageWrapper>
    </Wrapper>
  )
}

export default Questions
